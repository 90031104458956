
@import 'compass/css3';
@import 'mixin';

$sv: "screen and  (orientation: portrait)";
$ss: "screen and (max-device-width : 1024px)"; //small screen
$ssv: "screen and (max-device-width : 1024px) and  (orientation: portrait)";
$font: 'Lato', 'Open Sans', sans-serif;
$grey: #8e8e8e;

body, html{
	margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    cursor: url(../img/cursor.png), auto;
}

h1,h2,h3,p{
	font-family: $font;
	font-weight: 300;
}

// =========================================
body{
	font-family: helvetica;
    font-weight: 100;
    font-size: 30px;
    line-height: 1.6;
    transition: all 1s;
	background: #000;
	a,h1,h2,p{
		color: #fff;
	}

}

img{
	width: 100%;
	height: auto;
}
.fullImgBox{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	overflow: hidden;
	background-position: center;
	img{
		height: 100%;
		width: auto;
		@media #{$sv}{
			width: 100%;
			height: auto;
		}
	}
}






::-webkit-scrollbar{
	background: none;
/* 	background: rgba(56,56,56,0.3); */
/* 	background: #2e2e2e; */
    width: 0.3vw;
  //   &:hover{
		// width: 10px;
  //   }
}


::-webkit-scrollbar-thumb {
    background: rgba(125,125,125,0.1);
	background: #434343;
	background: rgba(0, 0, 0, 0.5);
/*     -webkit-border-radius: 1ex; */
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
	background: rgba(125,125,125,0.05);
}

// ==============================================================
header{
	position: absolute;
	top: 0;
	z-index: 9;
}


#logo{
	z-index: 100;
	width: 380px;
	// margin: 50px auto;
	position: fixed;
	left: 50vw;
	top: 70px;
	margin-left: -1.5vw;
	@include transform(translate(-50%, -50%));
	cursor: none;
	mix-blend-mode: color-dodge;
	filter: invert(0.8);
	transition: filter 2s, transform 0.5s, all 0.3s;
	// @include animation('logoOut 2s forwards','logoModeSwitch 1s');
	@include animation('logoModeSwitch 1s');
	a{
		cursor: none;
	}
	&:hover{
		// transition: filter 1s, transform 0.3s;
		// transform: translatex(-50%) scale(1.05);
		// mix-blend-mode: unset;
		// filter: invert(0);
		@include animation('logoOn 0.5s forwards');
	}
	&:active{
		transition: filter 0.3s, transform 0.5s, all 0.5s;
		filter: invert(1) !important;
	}
	@media #{$ss}{
		width: 30vw;
		top: 5vw;
	}
	@media #{$sv}{
		width: 80vw;
		top: 10vw;
	}
}

@include keyframes(logoOn){
	0% {
		filter: invert(0.8);
		mix-blend-mode: unset;
		@include transform(translate(-50%, -50%) scale(1));
	}
	100% {
		filter: invert(0);
		mix-blend-mode: unset;
		@include transform(translate(-50%, -50%) scale(1.05));
	}
}

@include keyframes(logoOut){
	0%{
		filter: invert(0);
		mix-blend-mode: color-dodge;
		-webkit-transform: translatex(-50%) scale(1.03);
			-ms-transform: translatex(-50%) scale(1.03);
				transform: translatex(-50%) scale(1.03);
	}
	100%{
		filter: invert(0.8);
		mix-blend-mode: color-dodge;
		-webkit-transform: translatex(-50%) scale(1);
			-ms-transform: translatex(-50%) scale(1);
				transform: translatex(-50%) scale(1);
	}
}

nav.navigation{
	text-align: center;
	a{
		display: inline-block;
		font-size: 20px;
		padding-bottom: 3px;
	    text-decoration: none;
	    letter-spacing: 3px;
		width: 200px;
		text-align: center;
		// margin: 0 50px ;
		// position: absolute;
		position: fixed;
		top: 47px;
		@media #{$sv}{
			width: 120px;
			font-size: 14px;
		}
	}
	a.left{
		left: 10%;
		@media #{$sv}{
		left: 5%;
		}
	}
	a.right{
		left: 100%;
		margin-left: -10%;
		@media #{$sv}{
			right: 5%;
		}
	}
}

nav.pagination{
	a{
		display: inline-block;
	    padding: 5px;
	    width: 50px;
	    cursor: none;
	    text-decoration: none;
	    font-family: sans-serif;
	    font-size: 36px;
	    line-height: 14px;
	    @include transition(all 0.3s)
	    img{
	    		width: 100%;
	    		height: auto;
	    }
		@media #{$sv}{
			padding: 10px;
			width: 40px;
		}
	}
	a.left{
		position: fixed;
		top: 50%;
		left: 5px;
		@media #{$sv}{
			left: 0;
		}
		&:hover{
			left: 15px;
			@include transform(scale(1.5));
		}
		// left: 10%;
	}
	a.right{
		position: fixed;
		top: 50%;
		right: 5px;
		@include transform(scale(-1,1));
		&:hover{
			right: 15px;
			@include transform(scale(-1.5, 1.5));
		}
		@media #{$sv}{
			right: 0;
		}
	}

}














#strobe{
	pointer-events: none;
	position: fixed;
	z-index: 999;
	width: 100vw;
	height: 100vh;
	display: none;
	mix-blend-mode: difference;
	.outer{
		position: fixed;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		@include animation('OpacityStrobe 150ms steps(1,end) infinite');
	}
	.inner{
		pointer-events: none;
		position: fixed;
		z-index: 999;
		width: 100vw;
		height: 100vh;
		@include animation('color 20s infinite');
	}
	
}

@include keyframes(OpacityStrobe){
	50%{
		opacity: 1;
	}
}

@include keyframes(color){
	0%{
		opacity: 1;
		background: #2f4a98;
	}
	50%{
		opacity: 0.5;
		background: #4c4c4c;
	}
	80%{
		opacity: 0.5;
		background: #fff;
	}
	100%{
		opacity: 0.8;
		background: #2f4a98;
	}
	// }
	// 31%{
	// 	background: #00ff00;
	// }

	// 40%{
	// 	background: #0000ff;
	// }
	// 61%{
	// 	background: #ff0000;
	// }
	// 70%{
	// 	background: #272727;
	// }
	// 100%{
	// 	background: #909090;
	// }

}













// ==============================================================
#pjax-container{
	position: absolute;
	top: 0;
	z-index: 0;
	width: 100vw;
	min-height: 100vh;
	transition: filter 0.15s;
	// opacity: 0;
	// animation: mainFadeIn 5s forwards;
	&.fx{
		filter: grayscale(100%) blur(15px);
	}
	// margin-top: 200px;
}
@include keyframes(mainFadeIn){
	0%{
		-webkit-transform: scale(1.05);
			-ms-transform: scale(1.05);
				transform: scale(1.05);
		opacity: 0;
	}
	100%{
		-webkit-transform: scale(1);
			-ms-transform: scale(1);
				transform: scale(1);
		opacity: 1;
	}
}

main{
	margin: 0px auto;
	width: 100vw;
	@media #{$sv}{
		width: 100%;
	}
}




.intro.text{
    text-align: center;
    font-size: 16px;
}
.text.wrap{
	margin-bottom: 100px;
}


#project{
	overflow: hidden;
	#gallery{

		img{
			width: 100%;
			height: auto;
			display: block;
		}
		img:first-child{
			@media #{$sv}{
				height: 100vh;
				width: auto;
				left: 50vw;
				position: relative;
				-webkit-transform: translateX(-50%);
					-ms-transform: translateX(-50%);
						transform: translateX(-50%);
			}
		}
	}
	#projDesc{
		width: 100%;
		min-height: 100vh;
		display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
		display: -ms-flexbox;  /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
		display: flex;
		align-items: center; 
		justify-content: center;
		.inner{
			width: 50%;
			letter-spacing: 0;
			@media #{$sv}{
				width: 70%;
			}
		}
		h1,h2,p{
			text-align: center;
			color: $grey;
			font-family: $font;
		}
		h1{
			font-size: 35px;
			text-transform: uppercase;
			margin: 45px 0;
		}
		h2{
			font-size: 18px;
			text-transform: uppercase;
			margin: 0;
		}
		p{
			font-size: 21px;
			margin-top: 55px;
			@media #{$sv}{
				font-size: 16px;
			}
		}
	}
}


#aboutBut{
	cursor: none;
	width: 60px;
	height: 60px;
	position: fixed;
	right: -0.3vw;
	bottom: 0;
	z-index: 9;
	transition: all 0.3s;
	filter: invert(0	); 
	&.fx{
		// filter: grayscale(100%);
		@include animation('strobe 100ms steps(1,end) infinite');
		&:hover{
			filter: invert(0);
			@include animation('strobe 70ms steps(1,end) infinite');
		}
	}
	&:hover{
		filter: invert(1	); 
		// animation:strobe 1000ms steps(1,end) infinite;
		// animation-duration:10ms;
		// animation-play-state:running;
	}
}

@include keyframes(strobe) {
	50% {
		filter: invert(1); 
	}
	// 50%{background:black; color:white;}
	// 50% { background:black; color:white; }
}
#about{
	display: none;
	// display: flex; 
	// opacity: 0;
	position: fixed;
	z-index: 101;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	mix-blend-mode: difference;
	h1{
		font-size: 28px;
		@media #{$sv}{
			font-size: 6.5vw;
		}
	}
	p{
		font-size: 27px;
		font-weight: 100;
		@media #{$sv}{
			font-size: 20px;
		}
	}
	.txt{
		width: 50%;
		@media #{$sv}{
			width: 80%;
		}
	}
	.inner{
		position: absolute;
		width: 100%;
		height: 100%;
		color: #fff;
		align-items: center; 
		justify-content: center;
		display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
		display: -ms-flexbox;  /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
		display: flex;
		text-align: center;
		@media #{$sv}{
			width: 100%;
			height: 100%;
		}
	}
	a{
		display: inline-block;
		width: 80px;
		padding: 10px;
		margin: 0 10px;
		height: auto;
		transition: all 0.3s;
		&:hover{
			filter: invert(1);
		}
		@media #{$sv}{
			width: 13vw;
			margin: 0;
		}
	}
}

// HOME

#homeCover{
	background-size: cover;
	video{
		min-width: 100vw;
		min-height: 100vh;
		position: absolute;
		left: 50%;
		top: 50%;
		@include transform(translate(-50%, -50%));
		@media #{$sv}{
			@include transform(translate(-50%, -50%) rotate(-90deg));
			min-width: 100vh;
			min-height: 100vw;
		}
	}
	@include animation('fadeIn 3s');
}
@include keyframes(fadeIn){
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

body.page-home #logo{
	@include animation('none');
	mix-blend-mode: unset;
	filter: invert(0);
	top: 50%;
	left: 50%;
	width: 30vw;
	@include transform( translate(-50%, -50%));
	// -webkit-transform: translatex(-50%) scale(1.03);
	// 	-ms-transform: translatex(-50%) scale(1.03);
	// 		transform: translatex(-50%) scale(1.03);
	&:hover{
	@include transform( translate(-50%, -50%) scale(1.05));
	}
	&.aboutMode{
		opacity: 0;
	}
	@media #{$sv}{
		width: 59vw;
	}
	@include animation('logoModeSwitch 1s');
	@include keyframes(logoModeSwitch){
		0%{
			// opacity: 1;
			filter: invert(0);
		}
		30%{
			// opacity: 0.1;
			filter: invert(1);
		}
		70%{
			// opacity: 0.1;
			filter: invert(1);
		}
		100%{
			// opacity: 1;
			filter: invert(0);
		}
	}
}



// VIDEO
.vidPlayer{
	position: relative;
	@media #{$sv}{
		background: #000;
		height: 100vh;
		width: 100vw;
	}
	.but.butPlay{
	}
	.butMute{
		position: absolute;
		right: 30px;
		bottom: 5vw;
		width: 3vw;
		height: 3vw;
		background: #ff0000;
		z-index: 9;
		display: none;

	}
	video{
		min-height: 100vh;
		min-width: 100vw;
		position: relative;
		margin-top: 50vh;
		left: 50vw;
		@include transform(translate(-50%, -50%));
		@media #{$ss}{
			min-height: 100vh;
			min-width: 100vw;
			height: auto;
			width: 100vw;
		}
		@media #{$ssv}{
			min-height: 100vh;
			min-width: 100vw;
			height: 100vh;
			width: auto;
		}
	}
}